<!--
 * @Description: 组织举报表单页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-29 10:44:26
 -->
<template>
  <div class="view-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :footerShow="footerShow"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
    <col2-detail>
      <col2-block title="举报人" >
        <col2-item :span="24" label="姓名" :text="detailItem.reportUserName"/>
        <col2-item :span="24" label="性别" :text="sexTypeMap[detailItem.reportSex]"/>
        <col2-item :span="24" label="联系电话" :text="detailItem.reportMobile"/>
      </col2-block>
      <col2-block title="被举报人" >
        <col2-item :span="24" label="被举报组织" :text="detailItem.orgName"/>
        <col2-item :span="24" label="地址信息" :text="detailItem.address"/>
        <col2-item :span="24" label="组织超管" :text="detailItem.managerName"/>
        <col2-item :span="24" label="联系电话" :text="detailItem.managerMobile"/>
        <col2-item :span="24" label="举报原因" :text="detailItem.reportReason"/>
      </col2-block>
      <col2-block title="操作" v-if="footerShow">
        <el-form-item
          label="操作"
          prop="approveStatus"
          :rules="[{ required: true, message: '请选择操作类型', trigger: 'change' }]"
        >
          <v-select v-model="form.approveStatus" :options="statusOps" :width="width"></v-select>
        </el-form-item>
        <el-form-item
          label="操作说明"
          prop="approveComments"
          :rules="[{ required: true, message:'请输入操作说明', trigger: 'blur' }]"
        >
          <v-textarea v-model="form.approveComments" placeholder="请输入操作说明"></v-textarea>
        </el-form-item>
        <el-form-item
          v-if="form.approveStatus === 3"
          label="修正房号"
          prop="approveHouse"
          :rules="[{ required: true, message: '选项不能为空', trigger: 'change' }]"
        >
          <div class="content-wrapper">
            <v-select2
              v-model="form.approveHouse"
              v-bind="houseParams"
              placeholder="查询房号"
              :width="width">
            </v-select2>
            <span class="right-tip">注：如果确认被举报人的房号信息，可直接修正房号。</span>
          </div>
        </el-form-item>
      </col2-block>
      <col2-block title="操作" v-if="!footerShow">
        <col2-item :span="24" label="操作" :text="statusMap[detailItem.approveStatus]"/>
        <col2-item :span="24" label="操作说明" :text="detailItem.approveComments"/>
      </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>

// 引用的类
import { getOrgComplaintInfoURL, orgComplaintDealURL, getHouseListURL } from './api'
import { statusOps, statusMap, sexTypeMap } from './map'
import { Col2Block, Col2Item, Col2Detail } from 'components/bussiness'

export default {
  name: 'OrgTipOffDetail',
  /// 引用的组件处理
  components: {
    Col2Block,
    Col2Item,
    Col2Detail
  },

  mounted () {
    const { id, edit } = this.$route.query
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id })
    }
    let title = edit === '0' ? '编辑' : '查看'
    this.$setBreadcrumb(title)
  },

  /// 必有方法
  data () {
    return {
      width: 180,
      submitUrl: orgComplaintDealURL,
      submitConfig: {
        submitMethod: 'post',
        submitUrl: orgComplaintDealURL,
        queryUrl: getOrgComplaintInfoURL
      },
      sexTypeMap: sexTypeMap,
      statusMap: statusMap,
      detailItem: {},
      //
      form: {
        id: undefined,
        approveStatus: undefined,
        approveComments: '',
        approveHouse: ''
      },
      statusOps: statusOps(2).slice(0, statusOps(2).length - 1),
      footerShow: false,
      houseParams: {
        searchUrl: getHouseListURL,
        method: 'POST',
        request: {
          text: 'roomNum',
          value: 'houseId'
        }
      }
    }
  },

  methods: {
    update (data) {
      if (data.approveStatus === 3) {
        data.approveHouse = data.approveHouseId
      }
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
      this.detailItem = data
      this.footerShow = data.approveStatus === 0
      if (!this.footerShow) {
        this.$setBreadcrumb('查看')
      }
    },

    submitBefore (data) {
      if (data.approveStatus !== 3) {
        data.approveHouse = undefined
      }
      let postData = {}
      Object.keys(data).forEach(item => {
        if (data[item] !== undefined) {
          if (typeof data[item] === 'string') {
            if (data[item].length) {
              postData[item] = data[item]
            }
          } else {
            postData[item] = data[item]
          }
        }
      })
      return postData
    }
  }
}
</script>

<style lang="scss">
.view-wrapper {
  display: flex;

    .content-wrapper {
      display: flex;
      justify-content: flex-start;

      .right-tip {
        margin-left: 15px;
      }
    }
}
</style>
