var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              footerShow: _vm.footerShow,
              form: _vm.form,
              submitBefore: _vm.submitBefore,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "举报人" } },
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "姓名",
                      text: _vm.detailItem.reportUserName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "性别",
                      text: _vm.sexTypeMap[_vm.detailItem.reportSex],
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "联系电话",
                      text: _vm.detailItem.reportMobile,
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "被举报人" } },
                [
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "被举报组织",
                      text: _vm.detailItem.orgName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "地址信息",
                      text: _vm.detailItem.address,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "组织超管",
                      text: _vm.detailItem.managerName,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "联系电话",
                      text: _vm.detailItem.managerMobile,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      span: 24,
                      label: "举报原因",
                      text: _vm.detailItem.reportReason,
                    },
                  }),
                ],
                1
              ),
              _vm.footerShow
                ? _c(
                    "col2-block",
                    { attrs: { title: "操作" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "操作",
                            prop: "approveStatus",
                            rules: [
                              {
                                required: true,
                                message: "请选择操作类型",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps, width: _vm.width },
                            model: {
                              value: _vm.form.approveStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "approveStatus", $$v)
                              },
                              expression: "form.approveStatus",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "操作说明",
                            prop: "approveComments",
                            rules: [
                              {
                                required: true,
                                message: "请输入操作说明",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-textarea", {
                            attrs: { placeholder: "请输入操作说明" },
                            model: {
                              value: _vm.form.approveComments,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "approveComments", $$v)
                              },
                              expression: "form.approveComments",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.approveStatus === 3
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "修正房号",
                                prop: "approveHouse",
                                rules: [
                                  {
                                    required: true,
                                    message: "选项不能为空",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "content-wrapper" },
                                [
                                  _c(
                                    "v-select2",
                                    _vm._b(
                                      {
                                        attrs: {
                                          placeholder: "查询房号",
                                          width: _vm.width,
                                        },
                                        model: {
                                          value: _vm.form.approveHouse,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "approveHouse",
                                              $$v
                                            )
                                          },
                                          expression: "form.approveHouse",
                                        },
                                      },
                                      "v-select2",
                                      _vm.houseParams,
                                      false
                                    )
                                  ),
                                  _c("span", { staticClass: "right-tip" }, [
                                    _vm._v(
                                      "注：如果确认被举报人的房号信息，可直接修正房号。"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.footerShow
                ? _c(
                    "col2-block",
                    { attrs: { title: "操作" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "操作",
                          text: _vm.statusMap[_vm.detailItem.approveStatus],
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "操作说明",
                          text: _vm.detailItem.approveComments,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }